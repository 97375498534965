import { createHttpClient } from "@intergamma/account";

import type {
  GiftCardBalanceCheckBody,
  GiftCardBalanceCheckResponse,
} from "./types/GiftCardBalanceCheck";
import { SessionData } from "./types/Session";
import { SignUpBody, SignUpResponse, Verify } from "./types/SignUp";
import type { SubscribeToEmailBody } from "./types/SubscribeToEmailBody";
import { UpdatePasswordBody } from "./types/UpdatePassword";
import { ResetPasswordBody } from "./types/ResetPassword";
import { ForgotPasswordBody } from "./types/ForgotPassword";
import { ResendVerificationBody } from "./types/ResendVerification";
import { ContactForm, VerifyRequest, ViesData } from "./generated";

export function createBaseApiService(MYACCOUNT_URL: string) {
  const baseClient = createHttpClient(`${MYACCOUNT_URL}/api`, {
    withCredentials: true,
  });

  return {
    getSessionData(): Promise<SessionData> {
      return baseClient.get("/session-data");
    },
    checkGiftCardBalance(
      payload: GiftCardBalanceCheckBody,
    ): Promise<GiftCardBalanceCheckResponse> {
      return baseClient.post<GiftCardBalanceCheckResponse>(
        "/giftcard",
        payload,
      );
    },
    subscribeToEmail(payload: SubscribeToEmailBody): Promise<void> {
      return baseClient.post<void>("/newsletter/subscribe", payload);
    },
    verify(type: string, hash: string, customerId?: string): Promise<Verify> {
      const url = customerId
        ? `/verify/${type}`
        : `/verify/${type}?hash=${hash}`;

      return baseClient.post(url, {
        hash,
        customerId,
      });
    },
    verifyEmail(payload: VerifyRequest): Promise<void> {
      return baseClient.post("/verify/email", payload);
    },
    updatePassword(body: UpdatePasswordBody): Promise<void> {
      return baseClient.post("/profile/password", body);
    },
    signUp(body: SignUpBody): Promise<SignUpResponse> {
      return baseClient.post<SignUpResponse>("/signup", body);
    },
    resetPassword(body: ResetPasswordBody): Promise<void> {
      return baseClient.post("/verify/password-reset", body);
    },
    forgotPassword(body: ForgotPasswordBody): Promise<void> {
      return baseClient.post("/password-reset", body);
    },
    /**
     * Resend verification based on customer id, only email is supported for now
     * @param body
     * @returns
     */
    resendVerification(body: ResendVerificationBody): Promise<Verify> {
      return baseClient.post("/verify/signup/resend", body);
    },
    viesCheck(vatNumber: string): Promise<ViesData> {
      return baseClient.get(`/vies?vatNumber=${vatNumber}`);
    },
    submitContact(body: ContactForm, language: string): Promise<void> {
      const formData = new FormData();

      body.attachment?.forEach((attachment) => {
        formData.append("attachment[]", attachment);
      });

      Object.entries(body).forEach(([key, value]) => {
        if (value !== "attachment") {
          formData.append(key, value as string);
        }
      });

      return baseClient.post("/contact-form/v2", body, {
        headers: {
          "Accept-Language": language,
          "Content-Type": "multipart/form-data",
        },
      });
    },
  };
}
