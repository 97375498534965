import { createHttpClient } from "@intergamma/account";

import { isAxiosError } from "axios";

import {
  EmailUpdateRequest,
  Profile,
  ProfileUpdate,
  ViolationsResponseModel,
} from "../base-api/generated";

export type Violations = Record<string, string>;

const mapViolationsToFields = (violations: ViolationsResponseModel) => {
  const fields: Violations = {};

  violations.violations.forEach((violation) => {
    if (violation.field !== undefined && violation.message !== undefined) {
      fields[violation.field] = violation.message;
    }
  });

  return fields;
};

export function createProfileService(MYACCOUNT_URL: string) {
  const baseClient = createHttpClient(`${MYACCOUNT_URL}/api/profile`, {
    withCredentials: true,
  });

  return {
    getProfile(): Promise<Profile> {
      return baseClient.get<Profile>("");
    },
    updateProfile(payload: ProfileUpdate): Promise<null> {
      return baseClient.put<null>("", payload).catch((error) => {
        if (!isAxiosError(error)) {
          return Promise.reject(new Error("Network response was not ok"));
        }

        if (!error.response?.data) {
          return Promise.reject(new Error("Response was not ok"));
        }

        const violations = mapViolationsToFields(error.response.data);

        return Promise.reject(violations);
      });
    },
    updateEmail(payload: EmailUpdateRequest): Promise<{ control: string }> {
      return baseClient.post<{ control: string }>("/email", payload);
    },
  };
}
