import type { Formula } from "@intergamma/common/formula";

import { getBaseConfig } from "./BaseConfig";
import { getSkin } from "./skin";

const domainMatch = document.location.href.match(/\/\/([^:/]+)/);
const domainFromUrl = domainMatch ? domainMatch[1] : "";

const baseConfig = getBaseConfig(domainFromUrl);
const { getTheme } = getSkin(baseConfig);
const theme = getTheme();

export const formula = {
  baseDomain: baseConfig.baseDomain,
  brand: baseConfig.brand,
  brandName: theme.brandName,
  id: theme.name,
} as Formula;
