import { useSuspenseQuery } from "@tanstack/react-query";

import { useBaseApiService } from "~/features/shared/ServicesContext";

export const QUERY_KEY = "session-data";

export function useSessionDataQuery() {
  const baseApiService = useBaseApiService();

  return useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: baseApiService.getSessionData,
  });
}
