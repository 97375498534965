import { jsx as i } from "react/jsx-runtime";
import { forwardRef as d } from "react";
import a from "styled-components";
const c = d(
  ({ large: o, pill: r, maxLength: e, inline: t, ...n }, l) => /* @__PURE__ */ i(
    b,
    {
      ref: l,
      maxLength: e,
      $pill: !!r,
      $large: !!o,
      $inline: !!t,
      ...n
    }
  )
), p = (o) => o.$large ? "30px 30px 10px 10px" : o.$pill ? "0 17px" : "0 10px";
function u(o) {
  return o.theme.name === "karwei_nl" ? `
      opacity: 0.5;
      border: 1px solid ${o.theme.formInputBorderColor};
    ` : `
      background-color: #dfe4ea;
      color: #516371;
    `;
}
const b = a.input`
  width: 100%;
  height: ${(o) => o.$large ? 60 : 46}px;
  box-sizing: border-box;
  border: 1px solid ${(o) => o.theme.formInputBorderColor};
  border-radius: ${(o) => o.$pill ? 99 : o.theme.borderRadius}px;
  background-color: ${(o) => o.theme.inputBackgroundColor};
  padding: ${p};
  font-size: 1em;
  font-weight: ${(o) => o.$large ? "bold" : "normal"};
  color: ${(o) => o.theme.formInputTextColor};
  transition: all 0.2s;

  &:hover {
    border-color: ${(o) => o.theme.formInputBorderFocussedColor};
    &:disabled {
      border-color: ${(o) => o.theme.formInputBorderColor};
    }
  }

  &:focus {
    outline: 0;
    border-color: ${(o) => o.theme.formInputBorderFocussedColor};
    box-shadow: 0 0 0 3px ${(o) => o.theme.buttonHighlightColor};
  }

  &:read-only {
    border: none;
    background-color: transparent;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover {
    box-shadow: 0 0 0 1000px #f5f5f5 inset;
  }
  &:-webkit-autofill:focus {
    box-shadow:
      0 0 0 1000px #f5f5f5 inset,
      0 0 0 3px rgba(10, 39, 101, 0.2);
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: ${(o) => o.theme.formInputPlaceholderColor};
  }

  &:disabled {
    ${(o) => u(o)}
  }
`;
export {
  c as Input
};
