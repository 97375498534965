import { cn } from "@intergamma/common/cn";

import { forwardRef } from "../helpers/forwardRef";

export interface BreadcrumbItemProps {}

const defaultAs = "li" as const;

/**
 * Breadcrumb item, should be used within a Breadcrumb list
 */
export const BreadcrumbItem = forwardRef<BreadcrumbItemProps, typeof defaultAs>(
  ({ children, className, as, ...props }, ref) => {
    const Component = as ?? defaultAs;

    return (
      <Component
        ref={ref}
        className={cn(
          "relative m-0 py-0 font-primary text-base/6 font-normal text-neutral-500",
          "last-of-type:font-bold",
          className,
        )}
        {...props}
      >
        {children}
      </Component>
    );
  },
);
