import { ReactNode } from "react";

import { cn } from "@intergamma/common/cn";

import { forwardRef } from "../helpers/forwardRef";

type TitleProps = {
  as?: string;
  children: ReactNode;
  className?: string;
  size: "md" | "lg" | "xl" | "xxl";
};

export const Title = forwardRef<TitleProps, "h2">(
  // eslint-disable-next-line prefer-arrow-callback
  function Title({ children, as, size, className, ...props }, ref) {
    const Component = as ?? "h2";

    return (
      <Component
        ref={ref}
        className={cn(
          "m-0 p-0 font-primary text-brand-primary",
          size === "md" && "text-200/4 font-bold",
          size === "lg" && "text-400/5 font-bold",
          size === "xl" && "text-500/6 font-bold",
          size === "xxl" && "text-800/7 font-bold",
          className,
        )}
        {...props}
      >
        {children}
      </Component>
    );
  },
);
