import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";

import { Button } from "@intergamma/button";
import { Input } from "@intergamma/form/Input";
import { validateCode } from "@intergamma/account";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

const CODE_LENGTH = 4;

type VerifyChangeProps = {
  control: string;
  isLoading: boolean;
  isSuccess: boolean;
  verificationError: string | null;
  onSuccess: () => void;
  onSubmit: (code: string) => void;
  onResend?: () => void;
};

export function VerifyChange({
  control,
  isLoading,
  isSuccess,
  verificationError,
  onSuccess,
  onSubmit,
  onResend,
}: VerifyChangeProps) {
  const { t } = useTranslation([
    "register",
    "errors",
    "ig-form",
    "urls",
    "login-form",
  ]);

  const [code, setCode] = useState("");
  const [error, setError] = useState<string | null>(null);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const codeField = useRef<HTMLInputElement | null>(null);

  const [disableResend, setDisableResend] = useState(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const digits = value.replace(/\D/g, "");

    setCode(digits);
  };

  const submit = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (timer.current) {
      clearTimeout(timer.current);
    }

    const hasCode = code.length === CODE_LENGTH;

    if (!hasCode) {
      setError(t("register:errorNotValidCode"));

      return;
    }

    onSubmit(code);
  };

  useEffect(() => {
    // Add a small delay to the submit
    if (code.length === CODE_LENGTH && validateCode(code)) {
      codeField.current?.blur();
      setError(null);

      timer.current = setTimeout(() => {
        submit();
      }, 1000);
    } else if (timer.current) {
      clearTimeout(timer.current);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [code]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (verificationError) {
      setError(verificationError);
      window.requestAnimationFrame(() => {
        codeField.current?.focus();
      });
    }
  }, [verificationError]);

  const sendAgain = () => {
    if (onResend) {
      setCode("");
      onResend();
      // Disable the resend button for 30 seconds so people don't spam it.
      setDisableResend(true);
      setTimeout(() => {
        setDisableResend(false);
      }, 30000);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  return (
    <form
      noValidate
      onSubmit={submit}
      method="POST"
      className="mt-4 grid grid-cols-1 gap-4"
      data-testid="verify-form"
    >
      <div className="col-span-1">
        <div className="my-1 flex items-end justify-center gap-2">
          <div className="block w-full text-75 font-semibold">
            {t("login-form:verifyNewPasswordCodeLabel")}
            <CodeInput
              ref={codeField}
              pattern="[0-9]*"
              inputMode="numeric"
              placeholder="____"
              id="code"
              value={code}
              data-testid="code"
              name="verific" // This name is on purpose to prevent autofill from password managers.
              autoComplete="off"
              onChange={handleInputChange}
              maxLength={CODE_LENGTH}
            />
          </div>
          <div
            className="my-1 flex size-12 items-center justify-center rounded-lg border bg-white pt-[3px] text-brand-tertiary-a gamma:border-[#6d818d] karwei:border-[#5b6573]"
            data-testid={`control-character-${control}`}
          >
            {control}
          </div>
        </div>
        {error ? (
          <div className="mt-1 text-75/5 gamma:text-ignew-functional-quaternary-700 karwei:text-ignew-functional-quaternary-600">
            {error}
          </div>
        ) : (
          <div className="block text-75">
            {t("login-form:verifyNewPasswordCodeDescription")}
          </div>
        )}
      </div>
      <div className="col-span-1">
        <Button disabled={isLoading} $isLoading={isLoading} $duration={4} $wide>
          {isLoading ? t("register:isVerifying") : t("register:confirm")}
        </Button>
      </div>
      {onResend && (
        <div className="col-span-1">
          <button
            className="h-auto cursor-pointer border-none p-0 underline"
            disabled={disableResend}
            onClick={sendAgain}
            type="button"
          >
            {t("register:buttonLabelResendCode")}
          </button>
        </div>
      )}
    </form>
  );
}

const CodeInput = styled(Input)`
  width: 100%;
  line-height: 1rem;
  padding: 0.2rem 0 0 calc(50% - 36px);
  letter-spacing: 0.8rem;
  height: 48px;
  margin: 0.25rem 0;
`;
