import { lazy } from "react";

import { useTranslation } from "react-i18next";
import { RouteObject } from "react-router-dom";

import { Layout } from "~/layouts/Layout";

import { routeMapper } from "~/helpers/route-mapper";

import { useBaseConfig } from "~/config/MyAccountConfigContext";

const RegisterByLoyaltyCardPage = lazy(
  () => import("~/pages/RegisterByLoyaltyCardPage"),
);

const ActivateRegisterByLoyaltyCardPage = lazy(
  () => import("~/pages/ActivateRegisterByLoyaltyCardPage"),
);

const VerifyCashRegisterRegistrationPage = lazy(
  () => import("~/pages/VerifyCashRegisterRegistrationPage"),
);

const PurchaseStatusPage = lazy(
  () => import("~/pages/purchase/PurchaseStatusPage"),
);

const RequestReturnPage = lazy(() => import("~/pages/RequestReturnPage"));
const VerificationPage = lazy(() => import("~/pages/VerificationPage"));
const ExternalPage = lazy(() => import("~/pages/ExternalPage"));
const LoyaltyPointsToDiscountPage = lazy(
  () => import("~/pages/LoyaltyPointsToDiscountPage"),
);

const NewsletterSubscribePage = lazy(
  () => import("~/pages/NewsletterSubscribePage"),
);

const ContactPage = lazy(() => import("~/pages/ContactPage"));
const SignUpSuccessPage = lazy(() => import("~/pages/SignUpSuccessPage"));
const UnsubscribePage = lazy(() => import("~/pages/UnsubscribePage"));
const TestPage = lazy(() => import("~/pages/TestPage"));

export function useRegularRoutes(): RouteObject[] {
  const { t } = useTranslation("urls");
  const { country, isProduction } = useBaseConfig();

  return [
    {
      element: <Layout whiteBg />,
      children: [
        {
          path: `${t("/verification")}/:type`,
          element: <VerificationPage />,
        },
        {
          path: t("/orderstatus"),
          element: <PurchaseStatusPage />,
        },
        {
          path: t("/register-loyalty-card/activate"),
          element: <ActivateRegisterByLoyaltyCardPage />,
        },
        {
          path: t("/unsubscribe"),
          element: <UnsubscribePage />,
        },
        ...(!isProduction
          ? [{ path: "/form-test", element: <TestPage /> }]
          : []),
        {
          path: t("/service-and-contact/contact"),
          element: <ContactPage />,
        },
      ].map((route) => routeMapper(country, route)),
    },
    {
      element: <Layout />,
      children: [
        {
          path: t("/request-return"),
          element: <RequestReturnPage />,
        },
        {
          path: t("/register-loyalty-card"),
          element: <RegisterByLoyaltyCardPage />,
        },
        {
          path: t("/verify-cash-register-registration"),
          element: <VerifyCashRegisterRegistrationPage />,
        },
        {
          path: t("/newsletter/subscribe"),
          element: <NewsletterSubscribePage />,
        },
        {
          path: t("/sign-up/success"),
          element: <SignUpSuccessPage />,
        },
        {
          path: t("/loyalty-to-discount"),
          element: <LoyaltyPointsToDiscountPage />,
        },
        {
          path: t("/status"),
          element: <ExternalPage />,
        },
        {
          path: t("/montage-service"),
          element: <ExternalPage />,
        },
      ].map((route) => routeMapper(country, route)),
    },
  ];
}
