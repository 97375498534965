import { createHttpClient as L } from "@intergamma/account/login";
import { B as Ne, a as Se, b as we, c as Ce, d as xe, e as Ae, U as x, S as G, t as J, T as j, f as Oe } from "./LoadingHeader-Cin_6rR4.js";
import { C as ht, F as gt, H as pt, I as bt, g as yt, L as vt, h as Nt, i as St, j as wt } from "./LoadingHeader-Cin_6rR4.js";
import { useTranslation as f } from "react-i18next";
import { useTranslation as xt } from "react-i18next";
import { jsx as n, jsxs as l } from "react/jsx-runtime";
import { Container as Ee } from "@intergamma/container";
import { useState as b, useRef as T, useEffect as v, useMemo as N, useCallback as z, lazy as O, Suspense as B, Children as ke, isValidElement as Ie, cloneElement as De } from "react";
import { useLegacyTheme as U, useFormula as Le } from "@intergamma/theme/provider";
import { useQuery as E, keepPreviousData as Te, useQueryClient as V, useMutation as X } from "@tanstack/react-query";
import { DialogRoot as _e, UnstyledDialogTrigger as Me, DrawerContent as Be, DialogTitle as Ue, DialogTrigger as D, DialogBottomSheet as Z, DialogExtended as ee } from "@intergamma/dialog";
import { DropdownRoot as F, DropdownTrigger as R, DropdownContent as Y } from "@intergamma/dropdown";
import { cn as w } from "@intergamma/common/cn";
import { cn as I } from "@intergamma/common";
import { MapPin as Fe, LogOut as Re, ChevronRight as ne, ArrowLeft as Ye, X as Ke, ChevronDown as Pe, ChevronUp as qe, Star as ze } from "react-feather";
import { Anchor as S } from "@intergamma/anchor";
import { useConfig as C } from "@intergamma/config";
import { MainSearchBar as Qe, SearchBar as He } from "@intergamma/search-bar";
import { ListItem as A } from "@intergamma/list";
import { dispatchAdobeEvent as _ } from "@intergamma/adobe-tracking";
import { signIn as $e } from "aws-amplify/auth";
import { localStorage as Q } from "@intergamma/storage";
function te(e) {
  var o;
  const r = L(e.SHOPFRONT_URL, {
    withCredentials: !0
  }), t = ((o = e.locales) == null ? void 0 : o[0].split(/[_-]/)[0]) ?? "nl";
  return {
    getCategories(i) {
      return r.get(e.country === "be" ? `/${t}/resources/menu/${i}` : `/resources/menu/${i}`).then(
        (s) => s.map(
          (a) => ({
            ...a,
            type: "category",
            dataUrl: We(a.dataUrl)
          })
        )
      );
    }
  };
}
function We(e) {
  return e.replace("https://www.acceptatie.gamma.nl/resources/menu/", "").replace("https://www.gamma.nl/resources/menu/", "").replace("https://www.acceptatie.gamma.be/nl/resources/menu/", "").replace("https://www.acceptatie.gamma.be/fr/resources/menu/", "").replace("https://www.gamma.be/nl/resources/menu/", "").replace("https://www.gamma.be/fr/resources/menu/", "").replace("https://www.acceptatie.karwei.nl/resources/menu/", "").replace("https://www.karwei.nl/resources/menu/", "");
}
function Ge({ category: e, onSelect: r }) {
  return /* @__PURE__ */ n(
    "li",
    {
      className: w(
        "m-0 block h-[inherit] break-inside-avoid border-b gamma:border-[#a1b5c5] karwei:border-[#dce1e8] lg:flex lg:h-10 lg:items-center lg:border-none",
        e.divider && "mb-10 lg:mb-0"
      ),
      children: /* @__PURE__ */ l(
        "a",
        {
          className: w(
            "group m-0 flex h-auto w-full grow cursor-pointer items-center rounded-lg border-none bg-transparent py-4 text-left text-[1em] leading-[1em] text-brand-primary no-underline",
            "hover:underline",
            "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400",
            "lg:w-auto lg:grow-0 lg:p-0"
          ),
          href: e.url,
          onClick: (t) => {
            e.hasSubCategories && (r(e), t.preventDefault());
          },
          children: [
            e.icon === "store" && /* @__PURE__ */ n(Fe, { className: "mr-2 mt-[inherit] !size-4 align-middle" }),
            e.icon === "logout" && /* @__PURE__ */ n(Re, { className: "mr-2 mt-[inherit] !size-4 align-middle" }),
            e.name,
            e.hasSubCategories && /* @__PURE__ */ n(ne, { className: "ml-1 size-4 text-brand-primary transition motion-safe:group-hover:translate-x-[5px] lg:right-[inherit] lg:size-4" })
          ]
        }
      )
    }
  );
}
function Je({ categories: e, onSelect: r }) {
  return /* @__PURE__ */ n("div", { className: "relative", children: /* @__PURE__ */ n("div", { className: "overflow-hidden lg:min-h-[205px]", children: /* @__PURE__ */ n("ul", { className: "mx-[10px] list-none columns-1 gap-x-5 p-0 pb-5 text-[1em] lg:mx-0 lg:columns-4 lg:px-5 lg:text-[0.9em]", children: e.map((t) => /* @__PURE__ */ n(
    Ge,
    {
      category: t,
      onSelect: r
    },
    t.uid
  )) }) }) });
}
function je({ lastBreadcrumb: e }) {
  const { t: r } = f("ig-header");
  return /* @__PURE__ */ n("div", { className: "rounded-b-lg px-[10px] pb-[15px] lg:px-5 lg:py-[15px] lg:text-75/normal gamma:lg:bg-[#f0f2f6] karwei:lg:bg-[#f1f4f8]", children: /* @__PURE__ */ n(
    S,
    {
      className: "no-underline hover:underline",
      href: e.url,
      children: e.uid === "root" || e.uid === "root-mobile" ? r("ShowEntireCatalog") : r("ShowAllIn", { name: e.name })
    }
  ) });
}
function Ve({
  breadcrumbs: e,
  onBack: r,
  onClose: t
}) {
  const { t: o } = f("ig-header"), i = e.length;
  return /* @__PURE__ */ l("div", { className: "mt-[10px] flex items-center gap-2 p-[10px] lg:px-[20px]", children: [
    i >= 2 && /* @__PURE__ */ n(
      "button",
      {
        className: I(
          "grid size-8 cursor-pointer place-items-center rounded-full text-brand-primary lg:hidden",
          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
        ),
        onClick: () => r(e[i - 2]),
        type: "button",
        children: /* @__PURE__ */ n(Ye, { className: "size-6 stroke-1" })
      }
    ),
    /* @__PURE__ */ n("ul", { className: "m-0 flex flex-1 list-none items-center gap-2 text-ellipsis whitespace-nowrap", children: e.map((s, a) => /* @__PURE__ */ l(
      "li",
      {
        className: "group m-0 hidden items-center gap-2 p-0 last:flex lg:flex",
        children: [
          a === i - 1 ? /* @__PURE__ */ n("span", { className: "font-bold", title: s.name, children: s.name }) : /* @__PURE__ */ n(
            "a",
            {
              className: I(
                "rounded-lg text-inherit underline hover:text-brand-primary",
                "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
              ),
              href: s.url,
              onClick: (d) => {
                d.preventDefault(), r(s);
              },
              children: s.name
            }
          ),
          /* @__PURE__ */ n(ne, { className: "size-4 group-last:hidden" })
        ]
      },
      s.uid
    )) }),
    t && /* @__PURE__ */ n(
      "button",
      {
        className: I(
          "grid size-8 cursor-pointer place-items-center rounded-full text-brand-primary",
          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
        ),
        type: "button",
        onClick: t,
        "aria-label": o("close"),
        children: /* @__PURE__ */ n(Ke, { className: "size-6 stroke-1" })
      }
    )
  ] });
}
function H({
  onClose: e,
  onSelect: r,
  breadcrumbs: t,
  categories: o,
  onBack: i,
  lastBreadcrumb: s
}) {
  var h;
  const [a, d] = b(!1), c = T(!0), u = T(
    o ? (h = o[0]) == null ? void 0 : h.name : void 0
  );
  return v(() => {
    var p;
    const g = o ? (p = o[0]) == null ? void 0 : p.name : void 0;
    u.current !== g && g && (u.current = g, d(!1), setTimeout(() => {
      c.current && d(!0);
    }, 300));
  }, [o, s.name]), v(
    () => () => {
      c.current = !1;
    },
    []
  ), /* @__PURE__ */ l(
    "nav",
    {
      className: I(
        "overflow-hidden",
        !a && "motion-safe:animate-menu-slider"
      ),
      children: [
        /* @__PURE__ */ n(
          Ve,
          {
            onBack: i,
            breadcrumbs: t,
            onClose: e
          }
        ),
        /* @__PURE__ */ n(Je, { categories: o ?? [], onSelect: r }),
        s.type === "category" && /* @__PURE__ */ n(je, { lastBreadcrumb: s })
      ]
    }
  );
}
function Xe({
  menuItem: e,
  store: r
}) {
  const t = U(), { t: o } = f("ig-header");
  if (e.type === "category")
    return [];
  if (e.uid === "root-mobile") {
    const i = [
      {
        dataUrl: "categories",
        uid: "catalog",
        type: "category",
        name: o("Categories"),
        url: o("/catalog"),
        hasSubCategories: !0
      },
      {
        uid: "advice",
        type: "menu-item",
        name: o("Advice"),
        url: o("/advice"),
        hasSubCategories: !1
      },
      {
        uid: "services",
        type: "menu-item",
        name: o("ServicesMobile"),
        url: o("/services"),
        hasSubCategories: !1
      },
      {
        uid: "promotions",
        type: "menu-item",
        name: o("Promotions"),
        url: o("/promotions"),
        hasSubCategories: !1
      }
    ];
    return t.name === "karwei_nl" && i.push({
      uid: "inspiration",
      type: "menu-item",
      name: o("Inspiration"),
      url: o("/inspiration"),
      hasSubCategories: !1
    }), i.push({
      uid: "store",
      name: r != null && r.name ? r.name : o("Stores"),
      url: o("/stores"),
      hasSubCategories: !1,
      type: "menu-item",
      icon: "store",
      divider: !0
    }), i;
  }
  return [];
}
function $({
  store: e,
  rootCategory: r
}) {
  const [t, o] = b([r]);
  v(() => {
    o([r]);
  }, [r]);
  const i = t[t.length - 1], s = Xe({
    menuItem: i,
    store: e
  });
  function a(c) {
    const u = t.findIndex(
      (h) => h.uid === c.uid
    );
    u > -1 && o(t.slice(0, u + 1));
  }
  function d(c) {
    c.hasSubCategories && o([...t, c]);
  }
  return {
    simpleMenuItems: s,
    onBack: a,
    onSelect: d,
    breadcrumbs: t,
    setBreadcrumbs: o,
    lastBreadcrumb: i
  };
}
function Ze() {
  var e;
  (e = document.querySelector("html")) == null || e.classList.add("panel-active");
}
function en() {
  var e;
  (e = document.querySelector("html")) == null || e.classList.remove("panel-active");
}
function nn({
  categories: e,
  store: r,
  onSelect: t
}) {
  const { t: o } = f("ig-header"), [i, s] = b(!1), [a, d] = b(!1), c = T(null), u = N(
    () => new URLSearchParams(
      typeof window < "u" ? window.location.search : void 0
    ),
    []
  ), h = N(
    () => ({
      dataUrl: "categories",
      uid: "root",
      name: o("Catalog"),
      url: o("/catalog"),
      hasSubCategories: !0,
      type: "category"
    }),
    [o]
  ), g = N(
    () => ({
      uid: "root-mobile",
      name: o("Menu"),
      url: "#",
      hasSubCategories: !0,
      type: "menu-item"
    }),
    [o]
  ), {
    breadcrumbs: p,
    lastBreadcrumb: y,
    onBack: me,
    onSelect: fe
  } = $({ store: r, rootCategory: h }), {
    breadcrumbs: he,
    lastBreadcrumb: k,
    onBack: ge,
    onSelect: pe,
    simpleMenuItems: be
  } = $({ store: r, rootCategory: g });
  function ye(m) {
    m.type === "category" && t(m), pe(m);
  }
  function ve(m) {
    m.type === "category" && t(m), fe(m);
  }
  v(() => {
    y.type === "category" && t(y);
  }, [y, t]), v(() => {
    k.type === "category" && t(k);
  }, [k, t]);
  function q(m) {
    m ? Ze() : en(), d(m);
  }
  return /* @__PURE__ */ n(Ne, { "aria-label": o("MainNavigation"), ref: c, children: /* @__PURE__ */ l(Se, { children: [
    /* @__PURE__ */ l(we, { children: [
      /* @__PURE__ */ n("div", { className: "contents lg:hidden", children: /* @__PURE__ */ l(
        _e,
        {
          open: a,
          onOpenChange: (m) => q(m),
          modal: !1,
          children: [
            /* @__PURE__ */ n(Me, { asChild: !0, children: /* @__PURE__ */ n(Ce, {}) }),
            /* @__PURE__ */ l(Be, { container: c.current, children: [
              /* @__PURE__ */ n("div", { className: "sr-only", children: /* @__PURE__ */ n(Ue, { children: o("Menu") }) }),
              /* @__PURE__ */ n(
                H,
                {
                  onClose: () => q(!1),
                  breadcrumbs: he,
                  categories: k.type === "category" && e ? e : be,
                  lastBreadcrumb: k,
                  onBack: ge,
                  onSelect: (m) => ye(m)
                }
              )
            ] }),
            a && /* @__PURE__ */ n("div", { className: "fixed inset-0 z-[9999] bg-[hsl(200deg_60%_12%/75%)] motion-safe:animate-fade-in" })
          ]
        }
      ) }),
      /* @__PURE__ */ n("div", { className: "hidden lg:contents", children: /* @__PURE__ */ l(F, { open: i, onOpenChange: s, children: [
        /* @__PURE__ */ n(
          R,
          {
            className: w(
              "h-[46px] rounded-lg",
              "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
            ),
            children: /* @__PURE__ */ n(xe, { isOpen: i })
          }
        ),
        /* @__PURE__ */ l(
          Y,
          {
            withBackdrop: !0,
            container: c.current,
            align: "start",
            alignOffset: -121,
            sideOffset: -10,
            children: [
              u.has("shadow") && // eslint-disable-next-line tailwindcss/no-custom-classname
              /* @__PURE__ */ n("div", { className: "flex flex-col items-center bg-brand-primary p-4", children: "😎 😎 😎" }),
              /* @__PURE__ */ n(
                H,
                {
                  onClose: () => s(!1),
                  breadcrumbs: p,
                  categories: e,
                  lastBreadcrumb: y,
                  onBack: me,
                  onSelect: (m) => ve(m)
                }
              )
            ]
          }
        )
      ] }) })
    ] }),
    /* @__PURE__ */ n("div", { className: "hidden lg:contents", children: /* @__PURE__ */ n(Ae, {}) })
  ] }) });
}
const K = "base-navigation", Wn = K;
function tn() {
  const [e, r] = b(
    void 0
  ), t = C(), o = N(
    () => te(t),
    [t]
  ), i = z(
    (c) => o.getCategories(c),
    [o]
  ), s = z(async () => e ? i(e.dataUrl) : [], [e, i]), { data: a } = E({
    queryKey: [K, e == null ? void 0 : e.uid],
    queryFn: s,
    placeholderData: Te,
    throwOnError: !1
  });
  function d(c) {
    r(c);
  }
  return { categories: a, onSelect: d };
}
function Gn({ store: e }) {
  const { categories: r, onSelect: t } = tn();
  return /* @__PURE__ */ n(nn, { store: e, categories: r, onSelect: t });
}
function Jn({
  className: e,
  children: r,
  ...t
}) {
  return /* @__PURE__ */ n(
    "div",
    {
      className: w(
        "absolute right-0 top-0 flex h-[50px] items-center gap-[10px] pl-[10px] lg:relative lg:h-[46px]",
        e
      ),
      ...t,
      children: r
    }
  );
}
const rn = O(
  () => import("@intergamma/account/dialog").then((e) => ({
    default: e.Account
  }))
), on = O(
  () => import("@intergamma/account/dialog").then((e) => ({
    default: e.LoyaltyBenefits
  }))
);
function jn({
  user: e,
  container: r,
  onLogin: t,
  ...o
}) {
  const [i, s] = b(!1), [a, d] = b(!1);
  document.addEventListener("openAccountDialog", () => {
    s(!0);
  });
  function c() {
    s(!1);
  }
  v(() => {
    const p = document.getElementById("onetrust-consent-sdk");
    p && (p.style.pointerEvents = "auto");
  }, []), v(() => {
    a && s(!0);
  }, [a]);
  const { isMobile: u } = C(), { t: h } = f("ig-header"), g = u ? Z : ee;
  return e != null && e.isLoggedIn ? a ? /* @__PURE__ */ n(
    g,
    {
      trigger: /* @__PURE__ */ n(D, { children: /* @__PURE__ */ n(x, { user: e, href: h("/myaccount") }) }),
      open: i,
      onOpenChange: s,
      container: r,
      dialogTitle: h("LoyaltyCard"),
      children: /* @__PURE__ */ n(
        on,
        {
          close: () => c(),
          loyaltyCardNumber: e.defaultLoyaltyCardNumber
        }
      )
    }
  ) : /* @__PURE__ */ n(x, { user: e, href: h("/myaccount") }) : /* @__PURE__ */ n(
    g,
    {
      trigger: /* @__PURE__ */ n(D, { asChild: !0, children: /* @__PURE__ */ n(x, { user: e }) }),
      open: i,
      onOpenChange: s,
      container: r,
      dialogTitle: h("Login"),
      children: /* @__PURE__ */ n(B, { children: /* @__PURE__ */ n(
        rn,
        {
          ...o,
          setShowLoyaltyBenefits: d,
          onClose: () => c(),
          onLogin: (p) => t(p).then(() => {
            a || c();
          }),
          initLocation: "global_header"
        }
      ) })
    }
  );
}
const sn = O(
  () => import("@intergamma/account/dialog").then((e) => ({
    default: e.Account
  }))
), an = O(
  () => import("@intergamma/account/dialog").then((e) => ({
    default: e.LoyaltyBenefits
  }))
), W = O(
  () => import("@intergamma/theme-next").then((e) => ({
    default: e.IntergammaTheme
  }))
);
function Vn({
  user: e,
  container: r,
  onLogin: t,
  ...o
}) {
  const [i, s] = b(!1), [a, d] = b(!1), c = Le();
  document.addEventListener("openAccountDialog", () => {
    s(!0);
  });
  function u() {
    s(!1);
  }
  v(() => {
    const y = document.getElementById("onetrust-consent-sdk");
    y && (y.style.pointerEvents = "auto");
  }, []), v(() => {
    a && s(!0);
  }, [a]);
  const { isMobile: h } = C(), { t: g } = f("ig-header"), p = h ? Z : ee;
  return e != null && e.isLoggedIn ? a ? /* @__PURE__ */ n(
    p,
    {
      trigger: /* @__PURE__ */ n(D, { children: /* @__PURE__ */ n(x, { user: e, href: g("/myaccount") }) }),
      open: i,
      onOpenChange: s,
      container: r,
      dialogTitle: g("LoyaltyCard"),
      children: /* @__PURE__ */ n(W, { formula: c, children: /* @__PURE__ */ n(
        an,
        {
          close: () => u(),
          loyaltyCardNumber: e.defaultLoyaltyCardNumber
        }
      ) })
    }
  ) : /* @__PURE__ */ n(x, { user: e, href: g("/myaccount") }) : /* @__PURE__ */ n(
    p,
    {
      trigger: /* @__PURE__ */ n(D, { asChild: !0, children: /* @__PURE__ */ n(x, { user: e }) }),
      open: i,
      onOpenChange: s,
      container: r,
      dialogTitle: g("Login"),
      children: /* @__PURE__ */ n(B, { children: /* @__PURE__ */ n(W, { formula: c, children: /* @__PURE__ */ n(
        sn,
        {
          ...o,
          setShowLoyaltyBenefits: d,
          onClose: () => u(),
          onLogin: (y) => t(y).then(() => {
            a || u();
          }),
          initLocation: "global_header"
        }
      ) }) })
    }
  );
}
function Xn() {
  return /* @__PURE__ */ n(G, { children: /* @__PURE__ */ n(Qe, {}) });
}
function Zn(e) {
  return /* @__PURE__ */ n(G, { children: /* @__PURE__ */ n(He, { ...e }) });
}
function re() {
  return /* @__PURE__ */ n(Pe, { className: "pointer-events-none", size: 16 });
}
function oe() {
  return /* @__PURE__ */ n(qe, { className: "pointer-events-none", size: 16 });
}
function et({
  alternateURL: e,
  container: r
}) {
  const { t } = f("ig-header"), [o, i] = b(!1);
  return U().name !== "gamma_be" ? null : /* @__PURE__ */ l(F, { open: o, onOpenChange: i, children: [
    /* @__PURE__ */ n(R, { asChild: !0, children: /* @__PURE__ */ l(
      "button",
      {
        className: I(
          J,
          "h-auto cursor-pointer border-none bg-none"
        ),
        children: [
          /* @__PURE__ */ n("strong", { children: t("Language") }),
          o ? /* @__PURE__ */ n(oe, {}) : /* @__PURE__ */ n(re, {})
        ]
      }
    ) }),
    /* @__PURE__ */ n(
      Y,
      {
        className: "!z-[99999] !w-max",
        align: "end",
        onOpenAutoFocus: ln,
        container: r,
        children: /* @__PURE__ */ n("div", { className: "p-5 text-75/normal", children: /* @__PURE__ */ l("ul", { className: "m-0 list-none p-0", children: [
          /* @__PURE__ */ n(A, { children: /* @__PURE__ */ n(
            S,
            {
              className: "whitespace-nowrap no-underline hover:underline",
              lang: "nl",
              href: (e == null ? void 0 : e.nl) ?? "/nl",
              children: "Nederlands"
            }
          ) }),
          /* @__PURE__ */ n(A, { children: /* @__PURE__ */ n(
            S,
            {
              className: "whitespace-nowrap no-underline hover:underline",
              lang: "fr",
              href: (e == null ? void 0 : e.fr) ?? "/fr",
              children: "Français"
            }
          ) })
        ] }) })
      }
    )
  ] });
}
function ln(e) {
  const r = e.target;
  if (!r)
    return;
  const t = r.querySelector("a");
  t && (e.preventDefault(), t.focus());
}
function P(e) {
  const r = L(e, {
    withCredentials: !0
  });
  return {
    getUser() {
      return r.get("/api/session-data");
    },
    getNearbyStores(t) {
      return r.get(
        `/api/session-data/nearby-stores/${t.uid}`
      );
    },
    async changeStore(t) {
      return r.put(
        `/api/session-data/preferred-store/${t.uid}`
      );
    },
    async logout() {
      const t = await this.getGatewayCSRF();
      return r.post("/gateway/logout", null, {
        headers: {
          "X-IG-CSRF-TOKEN": t
        }
      });
    },
    async login(t) {
      const o = await this.getGatewayCSRF();
      return r.post(
        "/gateway/addcard/login",
        t,
        {
          headers: {
            "X-IG-CSRF-TOKEN": o
          }
        }
      );
    },
    async getGatewayCSRF() {
      return (await r.get("/gateway/session")).csrfToken;
    }
  };
}
function nt() {
  const { MYACCOUNT_URL: e } = C(), r = V(), t = N(
    () => P(e),
    [e]
  );
  function o(i) {
    return t.changeStore(i).then(() => {
      document == null || document.body.dispatchEvent(
        new window.CustomEvent("refreshStore", { detail: i })
      ), _({
        type: "change_store",
        data: {
          user_selected_value: i
        }
      });
    });
  }
  return X({
    mutationFn: o,
    throwOnError: !1,
    onSuccess: () => {
      r.invalidateQueries();
    }
  });
}
const se = "nearby-store";
function tt(e) {
  const { MYACCOUNT_URL: r } = C(), t = N(
    () => P(r),
    [r]
  );
  function o() {
    return e ? t.getNearbyStores(e) : [];
  }
  return E({
    queryKey: [se, e],
    queryFn: o,
    throwOnError: !1
  });
}
function ie({
  className: e,
  children: r,
  ...t
}) {
  return /* @__PURE__ */ n(
    "div",
    {
      className: w(
        "rounded-b-lg p-5 gamma:bg-[#f0f2f6] karwei:bg-[#f1f4f8]",
        e
      ),
      ...t,
      children: r
    }
  );
}
function ae({
  children: e,
  className: r,
  ...t
}) {
  return /* @__PURE__ */ n(
    "h3",
    {
      className: w(
        "mb-5 text-[1.2em] font-bold text-brand-primary",
        r
      ),
      ...t,
      children: e
    }
  );
}
function cn() {
  const { t: e } = f("ig-header");
  return /* @__PURE__ */ l(ie, { children: [
    /* @__PURE__ */ n(ae, { children: e("OtherStoresNearby") }),
    /* @__PURE__ */ n("div", { className: "mb-5 flex flex-col last:mb-0", children: /* @__PURE__ */ n("div", { className: "block w-full", children: /* @__PURE__ */ n("div", { className: "h-5 w-full animate-pulse rounded-sm bg-[#ddd]" }) }) }),
    /* @__PURE__ */ n("div", { className: "mb-5 flex flex-col last:mb-0", children: /* @__PURE__ */ n("div", { className: "block w-full", children: /* @__PURE__ */ n("div", { className: "h-5 w-full animate-pulse rounded-sm bg-[#ddd]" }) }) }),
    /* @__PURE__ */ n("div", { className: "mb-5 flex flex-col last:mb-0", children: /* @__PURE__ */ n("div", { className: "block w-full", children: /* @__PURE__ */ n("div", { className: "h-5 w-full animate-pulse rounded-sm bg-[#ddd]" }) }) }),
    /* @__PURE__ */ l("ul", { className: "m-0 mt-5 list-none p-0", children: [
      /* @__PURE__ */ n(A, { children: /* @__PURE__ */ n(S, { href: e("/stores/search"), children: e("AllStores") }) }),
      /* @__PURE__ */ n(A, { children: /* @__PURE__ */ n(S, { href: e("/stores/holidays"), children: e("SpecialOpeningHours") }) })
    ] })
  ] });
}
function un({ nearbyStore: e, onSaveStore: r }) {
  const { t } = f("ig-header");
  return /* @__PURE__ */ l("div", { className: "mb-5 grid grid-cols-2 last:mb-0", children: [
    /* @__PURE__ */ n("div", { className: "flex items-center", children: /* @__PURE__ */ n(
      "a",
      {
        className: "font-bold text-black no-underline hover:underline",
        href: t("/stores/details", e),
        children: e.name
      }
    ) }),
    /* @__PURE__ */ n("div", { className: "flex items-center", children: /* @__PURE__ */ l(
      "button",
      {
        className: "group flex h-auto cursor-pointer items-center border-none bg-transparent p-0 text-[1em] hover:underline",
        onClick: () => r(e),
        children: [
          /* @__PURE__ */ n(ze, { className: "mr-[5px] rounded-[20px] bg-white !p-[5px] group-hover:fill-current gamma:text-brand-primary karwei:text-[#111316]" }),
          t("SaveAsMyStore")
        ]
      }
    ) })
  ] });
}
function dn({ nearbyStores: e, onSaveStore: r }) {
  const { t } = f("ig-header");
  return /* @__PURE__ */ l(ie, { children: [
    /* @__PURE__ */ n(ae, { children: t("OtherStoresNearby") }),
    e.map((o) => /* @__PURE__ */ n(
      un,
      {
        nearbyStore: o,
        onSaveStore: r
      },
      o.uid
    )),
    /* @__PURE__ */ l("ul", { className: "m-0 mt-5 list-none p-0", children: [
      /* @__PURE__ */ n(A, { children: /* @__PURE__ */ n(S, { href: t("/stores/search"), children: t("AllStores") }) }),
      /* @__PURE__ */ n(A, { children: /* @__PURE__ */ n(S, { href: t("/stores/holidays"), children: t("SpecialOpeningHours") }) })
    ] })
  ] });
}
const mn = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY"
];
function fn({ store: e }) {
  const { t: r } = f("ig-header");
  return /* @__PURE__ */ l("div", { className: "p-5", children: [
    /* @__PURE__ */ n("h2", { className: "mb-5 text-[1.3em] font-bold text-brand-primary", children: e.name }),
    /* @__PURE__ */ l("div", { className: "mb-5 flex last:mb-0", children: [
      /* @__PURE__ */ n("div", { className: "flex-1 basis-1/2", children: /* @__PURE__ */ l("div", { className: "leading-normal", children: [
        e.address.street,
        " ",
        e.address.streetNumber,
        e.address.apartment ? `/${e.address.apartment}` : null,
        /* @__PURE__ */ n("br", {}),
        e.address.zipCode,
        " ",
        e.address.city,
        /* @__PURE__ */ n("br", {}),
        e.address.phone,
        /* @__PURE__ */ n("br", {}),
        /* @__PURE__ */ n("br", {}),
        /* @__PURE__ */ n(S, { href: r("/stores/details", { slug: e.slug }), children: r("ShowOnMap") })
      ] }) }),
      /* @__PURE__ */ n("div", { className: "flex-1 basis-1/2", children: e.openingHours.map((t) => /* @__PURE__ */ l("div", { className: "mb-5 flex last:mb-0", children: [
        /* @__PURE__ */ n("div", { className: "flex-1 basis-1/2", children: r(mn[t.dayOfWeek]) }),
        /* @__PURE__ */ n("div", { className: "flex-1 basis-1/2", children: t.opens && t.closes ? r("Timespan", { opens: t.opens, closes: t.closes }) : r("Closed") })
      ] }, t.dayOfWeek)) })
    ] })
  ] });
}
function hn({
  store: e,
  isLoadingNearbyStores: r,
  ...t
}) {
  return /* @__PURE__ */ l("div", { className: "w-[500px] font-primary text-75/normal tabular-nums", children: [
    /* @__PURE__ */ n(fn, { store: e }),
    r ? /* @__PURE__ */ n(cn, {}) : /* @__PURE__ */ n(dn, { ...t })
  ] });
}
function gn() {
  const { t: e } = f("ig-header");
  return /* @__PURE__ */ n(j, { href: e("/stores/search"), children: /* @__PURE__ */ n("strong", { children: e("Stores") }) });
}
function rt({
  store: e,
  isLoadingNearbyStores: r,
  container: t,
  ...o
}) {
  const [i, s] = b(!1);
  return e ? /* @__PURE__ */ l(F, { open: i, onOpenChange: s, children: [
    /* @__PURE__ */ l(R, { className: J, children: [
      /* @__PURE__ */ n("strong", { children: e.name }),
      i ? /* @__PURE__ */ n(oe, {}) : /* @__PURE__ */ n(re, {})
    ] }),
    /* @__PURE__ */ n(
      Y,
      {
        className: "z-[999999] !max-w-[500px]",
        align: "end",
        container: t,
        children: /* @__PURE__ */ n(
          hn,
          {
            ...o,
            store: e,
            isLoadingNearbyStores: r
          }
        )
      }
    )
  ] }) : /* @__PURE__ */ n(gn, {});
}
function ot() {
  const { t: e } = f("ig-header");
  return /* @__PURE__ */ n(j, { href: e("/stores/search"), children: /* @__PURE__ */ n(Oe, { className: "w-[120px]" }) });
}
function st({ children: e }) {
  const { t: r } = f("ig-header");
  return /* @__PURE__ */ n(
    "nav",
    {
      className: w(
        "absolute right-[220px] top-0 hidden h-[46px] items-center",
        "lg:right-0 lg:flex lg:h-[46px]"
      ),
      "aria-label": r("UserNavigation"),
      children: /* @__PURE__ */ n("ul", { className: "m-0 flex list-none items-center gap-[25px] p-0", children: e && ke.map(e, (t) => Ie(t) ? /* @__PURE__ */ n("li", { children: De(t) }) : null) })
    }
  );
}
const pn = O(
  () => import("./Alert-CxevVYNO.js").then((e) => ({
    default: e.Alert
  }))
);
function bn({ alerts: e, onDismissAlert: r }) {
  return /* @__PURE__ */ n(B, { fallback: null, children: e.map(({ sys: t, fields: o }) => /* @__PURE__ */ n(
    pn,
    {
      label: o.description,
      id: t.id,
      onDismissAlert: r
    },
    t.id
  )) });
}
function yn({ alerts: e, onDismissAlert: r }) {
  return /* @__PURE__ */ n(Ee, { children: /* @__PURE__ */ n(bn, { alerts: e, onDismissAlert: r }) });
}
const M = "disabledAlerts";
function vn() {
  if (typeof sessionStorage > "u")
    return [];
  const e = sessionStorage.getItem(M);
  if (!e)
    return [];
  try {
    return JSON.parse(e);
  } catch {
    return [];
  }
}
function Nn(e) {
  if (typeof sessionStorage > "u")
    return;
  const r = sessionStorage.getItem(M) || "[]";
  try {
    const t = JSON.parse(r);
    t.includes(e) || (t.push(e), sessionStorage.setItem(M, JSON.stringify(t)));
  } catch {
  }
}
const Sn = L("https://cdn.contentful.com/spaces/");
function wn({
  contentfulSpaceId: e,
  contentfulToken: r,
  contentfulLocale: t
}) {
  const o = "alert", i = r;
  return Sn.get(
    `${e}/environments/master/entries?content_type=${o}&access_token=${i}&locale=${t}`
  ).then((s) => {
    var a;
    return ((a = s == null ? void 0 : s.items) == null ? void 0 : a.length) > 0 ? s.items : [];
  });
}
const le = "alerts";
function Cn() {
  var c;
  const [e, r] = b(vn), { contentfulSpaceId: t, contentfulToken: o } = U(), { i18n: i } = f(), s = i.language.substring(0, 2) === "fr" ? "fr" : "nl", a = E({
    queryKey: [le],
    queryFn: () => wn({
      contentfulLocale: s,
      contentfulSpaceId: t,
      contentfulToken: o
    }),
    throwOnError: !1
  });
  function d(u) {
    Nn(u), r([...e, u]);
  }
  return {
    alerts: ((c = a.data) == null ? void 0 : c.filter(
      (u) => !e.includes(u.sys.id)
    )) ?? [],
    onDismissAlert: d
  };
}
function it() {
  const { alerts: e, onDismissAlert: r } = Cn();
  return /* @__PURE__ */ n(yn, { alerts: e, onDismissAlert: r });
}
function xn(e) {
  const r = L(e, {
    withCredentials: !0
  });
  return {
    async getNumberOfProducts() {
      const t = await r.get(
        "/resources/cart/numberOfProducts"
      );
      return Number.isNaN(t) ? 0 : t;
    }
  };
}
const ce = "number-of-products";
function at() {
  const { CHECKOUT_URL: e } = C(), r = N(
    () => xn(e),
    [e]
  );
  function t() {
    return r.getNumberOfProducts();
  }
  return E({
    queryKey: [ce],
    queryFn: t,
    throwOnError: !1
  });
}
const An = "wishlist";
function On() {
  var r;
  if (typeof Q > "u")
    return 0;
  const e = Q.getItem(An);
  return e ? (r = JSON.parse(e)) == null ? void 0 : r.length : 0;
}
const ue = "number-of-favorites";
function lt() {
  return E({
    queryKey: [ue],
    queryFn: On,
    throwOnError: !1,
    initialData: 0
  });
}
const de = "user";
function ct() {
  const { MYACCOUNT_URL: e } = C(), r = N(
    () => P(e),
    [e]
  );
  return E({
    queryKey: [de],
    queryFn: r.getUser,
    throwOnError: !1
  });
}
function ut() {
  const { t: e } = f("ig-header"), r = V();
  function t({ body: o }) {
    return $e({
      username: o.customerId,
      password: o.password,
      options: {
        authFlowType: "USER_PASSWORD_AUTH"
      }
    }).catch((i) => {
      const { name: s, message: a } = i;
      throw s === "NotAuthorizedException" ? a === "Password attempts exceeded" ? new Error(e("loginTooManyAttempts")) : new Error(e("loginInvalidPassword")) : s === "UserNotFoundException" ? new Error(e("loginUserNotFound")) : new Error(e("loginSomethingWentWrong"));
    });
  }
  return X({
    mutationFn: t,
    throwOnError: !1,
    onSuccess: (o, { disableAdobeEvent: i = !1, body: s }) => {
      i || _({
        type: "login_header",
        data: {
          kdbid: s.customerId
        }
      }), r.resetQueries({
        queryKey: [
          le,
          K,
          se,
          ue,
          ce,
          de
        ]
      });
    },
    onError: (o) => {
      _({
        type: "login_error_header",
        data: {
          user_message_type: (o == null ? void 0 : o.message) ?? ""
        }
      });
    }
  });
}
function dt(e) {
  return te(e).getCategories;
}
export {
  le as ALERTS_QUERY_KEY,
  K as BASE_NAVIGATION_QUERY_KEY,
  nn as BaseNavigation,
  ht as CartIconNavigation,
  gt as FavoritesIconNavigation,
  yn as HeaderAlerts,
  pt as HeaderContainer,
  bt as IconsNavigation,
  Jn as IconsNavigationContainer,
  et as LanguageSelector,
  yt as LoadingBaseNavigation,
  vt as LoadingHeader,
  Nt as LoadingIconsNavigation,
  St as LoadingSearch,
  ot as LoadingStores,
  wt as LoadingTopNavigation,
  Gn as MainBaseNavigation,
  it as MainHeaderAlerts,
  Xn as MainSearch,
  se as NEARBY_STORES_QUERY_KEY,
  ue as NUMBER_OF_FAVORITES_QUERY_KEY,
  Wn as QUERY_KEY,
  Zn as Search,
  G as SearchContainer,
  rt as StoreLocatorDropdown,
  Vn as StyledUserIconNavigation,
  st as TopNavigation,
  de as USER_QUERY_KEY,
  jn as UserIconNavigation,
  x as UserIconNavigationButton,
  dt as createGetAssortimentCategories,
  Ze as disablePageScroll,
  en as enablePageScroll,
  wn as getAlerts,
  vn as getSessionDisabledAlert,
  Nn as setSessionDisabledAlert,
  Cn as useAlertsQuery,
  tn as useCategoriesQuery,
  nt as useChangeStoreMutation,
  ut as useLoginMutation,
  tt as useNearbyStoresQuery,
  lt as useNumberOfFavorites,
  at as useNumberOfProductsQuery,
  xt as useTranslation,
  ct as useUserQuery
};
