import { cn } from "@intergamma/common/cn";

import {
  ChevronRight,
  type ChevronRightIconProps,
} from "../icons/ChevronRight";

export interface BreadcrumbDividerProps
  extends Omit<ChevronRightIconProps, "ref"> {}

/**
 * Breadcrumb divider Icon
 */
export function BreadcrumbDivider({
  className,
  ...props
}: BreadcrumbDividerProps) {
  return (
    <ChevronRight className={cn("text-neutral-500", className)} {...props} />
  );
}
